import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "../helpers";

// Connects to data-controller="publication"
export default class extends Controller {
  static targets = ["container", "partial"]

  static values = {
    associateUrl: String,
    associateAllUrl: String,
    dissociateUrl: String,
    dissociateAllUrl: String,
    sortUrl: String
  }

  // Send to back to sort based on the current order and the next one
  async sort(event) {
    const currentSortModel = this.partialTarget.dataset.sort.split('-')[0]
    const currentSortType = this.partialTarget.dataset.sort.split('-')[1]
    let sort = ''
    if (currentSortModel !== event.params.sortModel) {
      sort = `${event.params.sortModel}-asc`
    } else if (currentSortType === 'asc') {
      sort = `${currentSortModel}-desc`
    } else {
      sort = `${currentSortModel}-asc`
    }
    const body = {
      sort: sort
    }

    this.containerTarget.innerHTML = await this.fetchWrapper(this.sortUrlValue, "POST", body)
  }

  async togglePublication(event) {
    const body = {
      user_id: event.params.user,
      publication_id: event.params.id,
      sort: this.partialTarget.dataset.sort
    }

    if (event.target.checked) {
      this.containerTarget.innerHTML = await this.fetchWrapper(this.associateUrlValue, "POST", body)
    } else {
      this.containerTarget.innerHTML = await this.fetchWrapper(this.dissociateUrlValue, "DELETE", body);
    }
  }

  async toggleAll(event) {
    const body = {
      user_id: event.params.user,
      sort: this.partialTarget.dataset.sort
    }

    if (event.target.checked) {
      this.containerTarget.innerHTML = await this.fetchWrapper(this.associateAllUrlValue, "POST", body)
    } else {
      this.containerTarget.innerHTML = await this.fetchWrapper(this.dissociateAllUrlValue, "DELETE", body)
    }
  }

  async fetchWrapper(url, httpVerb, body) {
    const response = await fetch(
      url,
      {
        method: httpVerb,
        body: JSON.stringify(body),
        headers: {
          Accept: "text/plain",
          "X-CSRF-Token": getMetaValue("csrf-token"),
          "Content-Type": "application/json",
        },
      }
    )
    return response.text()
  }
}
