import { Controller } from "@hotwired/stimulus"

export default class FileInputController extends Controller {
  static get targets() {
    return ['value', 'input']
  }

  /**
   * Display the selected file name in the input field or target element.
   * 
   * @param {Event} evt - The event object triggered by the input.
   */
  display(evt) {
    const fileName = evt.target.value.split('\\').pop();
    
    if (this.valueTarget.nodeName === 'INPUT') {
      this.valueTarget.placeholder = fileName;
    } else {
      this.valueTarget.innerHTML = fileName;
    }
  }
}
