import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="icon-selector"
export default class extends Controller {
  static targets = ["modal"]
  
  selectedIcon = null;

 // Hide the modal by removing the "d-block" class
  hideModal() {
    this.modalTarget.classList.remove("d-block")
  }

  // Handle icon selection
  select(e) {
    Array.from(document.querySelectorAll('.icon-item.active')).forEach(
      (el) => el.classList.remove('active')
    )
    const element = e.target.closest(".icon-item")
    element.classList.add("active")
    this.selectedIcon = element.dataset.iconName
  }

  // Save the selected icon
  save() {
    // Set the selected icon value and update the preview
    document.getElementById("category_icon").value = this.selectedIcon
    document.getElementById("icon-preview").innerHTML = `<i class='${this.selectedIcon}'></i>`
    this.hideModal()
  }
}
