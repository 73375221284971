import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import Dropzone from "dropzone"
import {
  getMetaValue,
  findElement,
} from "../helpers"

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = ["input", "files"]

  connect() {
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
  }

  async remove_content(event) {
    event.preventDefault()
    const has_confirmed = confirm(event.srcElement.parentElement.dataset.confirmText)

    if (has_confirmed) {
      const response = await fetch(
          event.srcElement.parentElement.getAttribute("href"),
          {
            method: "DELETE",
            headers: {
              Accept: "text/plain",
              "X-CSRF-Token": getMetaValue("csrf-token"),
              "Content-Type": "application/json",
            },
          }
      )
      const content = await response.text()
      this.filesTarget.innerHTML = content
    }
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      this.dropzoneMessage.classList.add('d-none')
      this.spinnerDiv.classList.remove('d-none')
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start()
      }, 500)
    })

    this.dropZone.on("removedfile", (file) => {
      file.controller
    })

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort()
    })

    this.dropZone.on("processing", (file) => {
      this.submitButton.disabled = true
    })

    this.dropZone.on("queuecomplete", (file) => {
      this.submitButton.disabled = false
      this.spinnerDiv.classList.add('d-none')
      this.dropzoneMessage.classList.remove('d-none')
    })
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") }
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url") || ""
  }

  get maxFiles() {
    return 100
  }

  get maxFileSize() {
    return 3072
  }

  get acceptedFiles() {
    return "image/jpeg,image/jpg,image/png,image/gif,application/pdf,video/mp4,video/mov"
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true
  }

  get form() {
    return this.element.closest("form")
  }

  get submitButton() {
    return findElement(this.form, "input[type=submit], button[type=submit]")
  }

  get spinnerDiv() {
    return findElement(this.element, ".loader")
  }

  get dropzoneMessage() {
    return findElement(this.element, ".dropzone-msg-desc")
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.directUpload.create(async (error, attributes) => {
      if (error) {
        this.emitDropzoneError(error)
      } else {
        const response = await fetch(
          this.source.inputTarget.getAttribute("data-create-record-url"),
          {
            method: "POST",
            headers: {
              Accept: "text/plain",
              "X-CSRF-Token": getMetaValue("csrf-token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ blob: attributes }),
          }
        )
        const content = await response.text()
        this.source.filesTarget.innerHTML = content

        this.emitDropzoneSuccess()
      }
    })
  }

  // Private

  directUploadWillStoreFileWithXHR(xhr) {
    this.emitDropzoneUploading()
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

// Top level...
function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}


function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoProcessQueue: false,
    autoQueue: false,
    disablePreviews: true,
  })
}
