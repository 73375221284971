import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="inline-edit"
export default class extends Controller {

  static get targets() {
    return ["infos", "form", "content"]
  }

  // Display the form and hide the information section
  displayForm() {
    this.infosTarget.classList.add("d-none")
    this.formTarget.classList.remove("d-none")
  }

  // Update content using a PATCH request
  update(event){
    event.preventDefault() 
    const url = this.formTarget.action
    fetch(url, {
      method: "PATCH",
      headers: { "Accept": "text/plain" },
      body: new FormData(this.formTarget)
    })
    .then(response => response.text())
    .then((data) => {
      this.contentTarget.outerHTML = data
    })
  }
}
