import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static values = { conversationId: Number }
  static targets = ["messages", "contacts"]

  connect() {
    this.channel = createConsumer().subscriptions.create(
      { channel: "ChatChannel" },
      { received: (data) => this.#insertMessageAndScrollDown(data) }
    )
    this.messagesTarget.scrollTo(0, this.messagesTarget.scrollHeight)
  }
  disconnect() {
    this.channel.unsubscribe()
  }

  #updateMessage(data) {
    fetch(`/messages/${data.payload.message.id}`, {
      method: "GET",
      headers: { Accept: "text/plain" },
    })
    .then((response) => response.text())
    .then((content) => {
        if (this.conversationIdValue === data.payload.message.conversation_id) {
          this.messagesTarget.insertAdjacentHTML("beforeend", content)
          this.messagesTarget.scrollTo(0, this.messagesTarget.scrollHeight)
        }
      })
  }

  #updateContacts(data) {
    fetch(`/conversations/${this.conversationIdValue}/contacts`, {
      method: "GET",
      headers: { Accept: "text/plain" },
    })
      .then((response) => response.text())
      .then((data) => {
        this.contactsTarget.innerHTML = data
      })
  }

  #insertMessageAndScrollDown(data) {
    this.#updateMessage(data)
    this.#updateContacts(data)
  }

  resetForm(event) {
    event.target.reset()
  }
}
