import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "../helpers"

// Connects to data-controller="subcategory"
export default class extends Controller {
  static targets = ["container", "partial"]

  static values = {
    associateUrl: String,
    associateCategoryUrl: String,
    associateAllUrl: String,
    dissociateUrl: String,
    dissociateCategoryUrl: String,
    dissociateAllUrl: String,
    sortUrl: String,
  }

  /**
   * Send to back to sort based on the current order and the next one
   * @param {Event} event - The event object.
   */
  async sort(event) {
    const currentSortModel = this.partialTarget.dataset.sort.split("-")[0]
    const currentSortType = this.partialTarget.dataset.sort.split("-")[1]
    let sort = ""
    if (currentSortModel !== event.params.sortModel) {
      sort = `${event.params.sortModel}-asc`
    } else if (currentSortType === "asc") {
      sort = `${currentSortModel}-desc`
    } else {
      sort = `${currentSortModel}-asc`
    }
    const body = {
      sort: sort,
    }

    await this.updateContent(this.sortUrlValue, "POST", body)
  }

  /**
   * Toggles a subcategory for a user.
   * @param {Event} event - The event object.
   */
  async toggleSubcategory(event) {
    const body = {
      user_id: event.params.user,
      sub_category_id: event.params.id,
      sort: this.partialTarget.dataset.sort,
    }

    const url = event.target.checked
      ? this.associateUrlValue
      : this.dissociateUrlValue
    const verb = event.target.checked ? "POST" : "DELETE"

    await this.updateContent(url, verb, body)
  }

  /**
   * Toggles a category for a user.
   * @param {Event} event - The event object.
   */
  async toggleCategory(event) {
    const body = {
      user_id: event.params.user,
      category_id: event.params.category,
      sort: this.partialTarget.dataset.sort,
    }

    const url = event.target.checked
      ? this.associateCategoryUrlValue
      : this.dissociateCategoryUrlValue
    const verb = event.target.checked ? "POST" : "DELETE"

    await this.updateContent(url, verb, body)
  }

  /**
   * Toggles all categories for a user.
   * @param {Event} event - The event object.
   */
  async toggleAll(event) {
    const body = {
      user_id: event.params.user,
      sort: this.partialTarget.dataset.sort,
    }

    const url = event.target.checked
      ? this.associateAllUrlValue
      : this.dissociateAllUrlValue
    const verb = event.target.checked ? "POST" : "DELETE"

    await this.updateContent(url, verb, body)
  }

  /**
   * Updates the content by making a fetch request.
   * @param {string} url - The URL to send the request to.
   * @param {string} verb - The HTTP verb (e.g., "POST" or "DELETE").
   * @param {Object} body - The request body data.
   */
  async updateContent(url, verb, body) {
    const scrollLeftValue =
      this.containerTarget.querySelector(".table-attribution").scrollLeft
    this.containerTarget.innerHTML = await this.fetchWrapper(url, verb, body)
    this.containerTarget
      .querySelector(".table-attribution")
      .scrollTo(scrollLeftValue, 0)
  }

  /**
   * Makes a fetch request and returns the response as text.
   * @param {string} url - The URL to send the request to.
   * @param {string} httpVerb - The HTTP verb (e.g., "POST" or "DELETE").
   * @param {Object} body - The request body data.
   * @returns {Promise<string>} - A promise that resolves to the response text.
   */
  async fetchWrapper(url, httpVerb, body) {
    const response = await fetch(url, {
      method: httpVerb,
      body: JSON.stringify(body),
      headers: {
        Accept: "text/plain",
        "X-CSRF-Token": getMetaValue("csrf-token"),
        "Content-Type": "application/json",
      },
    })
    return response.text()
  }
}
